import { mapValues, keyBy } from 'lodash';
import { func } from '.';

/**
 * turns [{k: 1, v: 42}, {k:2, v: 100}] into {1: 42, 2: 100}
 */
export const mapAndKey = (data, key, fillValue) => {
    return mapValues(keyBy(data, key), fillValue);
};

/**
 * turns [1,2,3] into {1:1, 2:2, 3:3}
 * or [1,2,3] into {1:<fillValue>, 2:<fillValue>, 3:<fillValue>}
 */
export const arrToObj = (data, fillValue = null) => {
    return mapAndKey(data, null, (x) => func(fillValue ?? x)(x));
};
